import React, { useState } from 'react';

const managementSections = [
  { title: 'Cryptic (@c.r.y.p.t.i.c)', description: 'Director & Developer' },
  { title: 'Jay (@dr.jjj)', description: 'Director & Token User' },
  { title: 'Swirl (@swirl127)', description: 'Director & Token User' },
  { title: 'Almighty (@almightygzz)', description: 'Executive Manager & Token User' },
  { title: 'Fire (@fire7676)', description: 'Executive Manager' },
  { title: 'Wewster (@theewewster)', description: 'Community & Server Manager' },
  { title: 'Vig (@giv205)', description: 'Server Manager' },
  { title: 'Nampy (@takeanampy)', description: 'Server Manager' },
  { title: 'Eight (@eight122_)', description: 'Assistant Server Manager' },
  { title: 'Nemy (@meliodas105)', description: 'Assistant Server Manager' },
  { title: 'Frogge (@froggezs)', description: 'Assistant Server Manager' },
  { title: 'Ember (@ember135890)', description: 'Assistant Server Manager' },
];

const Management = () => {
  const [selectedSection, setSelectedSection] = useState(null);

  return (
    <div className="flex container mx-auto py-8">
      {/* Left Side: Management Sections List */}
      <div className="flex-1 mr-4">
        <h2 className="text-3xl font-extrabold mb-4 text-accentCyan">ZMA 3.3 Management Team</h2>

        {/* Section List */}
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {managementSections.map((section, index) => (
            <div
              key={index}
              className="bg-darker p-4 rounded shadow-lg cursor-pointer transition-transform transform hover:scale-105"
              onClick={() => setSelectedSection(section)}
            >
              <h4 className="text-lg font-semibold text-accentCyan">{section.title}</h4>
              <p className="text-sm text-lightGray">{section.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Section Details */}
      <div className="w-1/3 bg-darker p-4 rounded shadow-lg ml-auto">
        {selectedSection ? (
          <>
            <h3 className="text-xl font-bold text-accentCyan mb-2">{selectedSection.title}</h3>
            <p className="text-sm text-lightGray mb-2">{selectedSection.description}</p>
          </>
        ) : (
          <p className="text-lightGray">Click on a section to see more details.</p>
        )}
      </div>
    </div>
  );
};

export default Management;