import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
import Items from './pages/Items';
import Products from './pages/Products';
import Commands from './pages/Commands';
import Management from './pages/Management';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-dark text-lightGray">
        {/* Updated Header with Solid Title Color */}
        <header className="bg-darker p-4 shadow-md">
          <nav className="container mx-auto flex justify-between items-center">
            {/* Title with Solid White Color */}
            <h1 className="text-4xl font-bold text-white font-bangers drop-shadow-[0_5px_15px_rgba(0,0,0,0.7)]">
              <Link to="/">ZMA 3.3</Link>
            </h1>
            <ul className="flex space-x-4">
              <li><Link to="/" className="hover:text-accentCyan">Home</Link></li>
              <li><Link to="/commands" className="hover:text-accentCyan">Commands</Link></li>
              <li><Link to="/items" className="hover:text-accentCyan">Items</Link></li>
              <li><Link to="/products" className="hover:text-accentCyan">Products</Link></li>
              <li><Link to="/management" className="hover:text-accentCyan">Management</Link></li>
              {/* Updated the second WabbitBot link to Discord */}
              <li>
                <a
                  href="https://discord.gg/zma"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-accentCyan"
                >
                  Discord
                </a>
              </li>
            </ul>
          </nav>
        </header>

        <main className="container mx-auto py-8">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/commands" element={<Commands />} />
            <Route path="/items" element={<Items />} />
            <Route path="/products" element={<Products />} />
            <Route path="/management" element={<Management />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </main>

        <footer className="bg-darker p-4 text-center">
          <p>© 2024 Zombie Mini Arena</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;