import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="flex container mx-auto py-8">
      <div className="bg-darker p-6 rounded shadow-lg">
        <h1 className="text-3xl font-extrabold text-accentCyan mb-4">Privacy Policy</h1>
        <p className="text-lightGray mb-4">
          Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information when you use Zombie Mini Arena ("ZMA"), including its Starve.io server, Discord bot, and Website ("services").
        </p>

        <h2 className="text-xl font-bold text-accentCyan mb-2">1. Information We Collect</h2>
        <p className="text-lightGray mb-4">
          ZMA collects only publicly accessible data that you provide directly or indirectly through the use of our services. This includes:
        </p>
        <ul className="list-disc pl-6 text-lightGray mb-4">
          <li>Publicly visible information, such as your username, account statistics, and in-game actions.</li>
          <li>Technical usage data, such as IP addresses, device types, and server activity logs.</li>
          <li>Preferences or settings you configure while using our services.</li>
        </ul>
        <p className="text-lightGray mb-4">
          <strong>Note:</strong> We do not collect private or sensitive information, such as payment details, as all payments are processed securely by third-party platforms (e.g., Discord). ZMA has no access to or control over this data.
        </p>

        <h2 className="text-xl font-bold text-accentCyan mb-2">2. How We Use Your Information</h2>
        <p className="text-lightGray mb-4">
          The information we collect is used solely to enhance the functionality and quality of our services. Specifically, we use this data to:
        </p>
        <ul className="list-disc pl-6 text-lightGray mb-4">
          <li>Provide and maintain access to our services.</li>
          <li>Track and display account statistics or activity logs.</li>
          <li>Ensure security, detect abuse, and prevent unauthorized use of our services.</li>
          <li>Analyze usage trends to improve user experience.</li>
        </ul>

        <h2 className="text-xl font-bold text-accentCyan mb-2">3. Data Storage and Retention</h2>
        <p className="text-lightGray mb-4">
          Public data, such as usernames, account statistics, and other non-sensitive information, is stored in our database. We retain this data indefinitely to ensure service functionality and user continuity. Because the data we collect is non-private, we do not offer options for deletion or removal of stored data.
        </p>
        <p className="text-lightGray mb-4">
          If you no longer wish to have your public information stored, you must cease using our services, as continued use implies consent to our data retention practices.
        </p>

        <h2 className="text-xl font-bold text-accentCyan mb-2">4. Sharing Your Information</h2>
        <p className="text-lightGray mb-4">
          We do not sell or share your data with third parties for marketing or other purposes. However, we may share information with trusted third-party providers as required to:
        </p>
        <ul className="list-disc pl-6 text-lightGray mb-4">
          <li>Facilitate the operation of our services (e.g., server hosting).</li>
          <li>Comply with legal obligations or protect the safety of our users and platform.</li>
        </ul>
        <p className="text-lightGray mb-4">
          Additionally, by using our services, you are also subject to the data handling practices of the platforms on which our services operate, such as Starve.io, Discord, and any other third-party platform you engage with.
        </p>

        <h2 className="text-xl font-bold text-accentCyan mb-2">5. Data Security</h2>
        <p className="text-lightGray mb-4">
          We implement reasonable measures to protect your public information from unauthorized access, alteration, or misuse. However, no system is entirely secure, and we cannot guarantee complete protection of your data. You use our services at your own risk.
        </p>

        <h2 className="text-xl font-bold text-accentCyan mb-2">6. Your Rights</h2>
        <p className="text-lightGray mb-4">
          While we do not collect private or sensitive data, you have the right to understand how your public data is being used. If you have questions about your stored information, you may contact us at <span className="text-accentCyan">discord.gg/zma</span>. Note that we do not offer data deletion services due to the public nature of the collected information.
        </p>

        <h2 className="text-xl font-bold text-accentCyan mb-2">7. Compliance with Third-Party Policies</h2>
        <p className="text-lightGray mb-4">
          By using ZMA's services, you also agree to the data collection, storage, and usage practices of the platforms where our services operate. This includes, but is not limited to:
        </p>
        <ul className="list-disc pl-6 text-lightGray mb-4">
          <li>Starve.io for in-game interactions and data handling.</li>
          <li>Discord for bot interactions, including usernames, messages, and server activity.</li>
        </ul>
        <p className="text-lightGray mb-4">
          Please review the privacy policies of these platforms to understand their data handling practices.
        </p>

        <h2 className="text-xl font-bold text-accentCyan mb-2">8. Changes to This Policy</h2>
        <p className="text-lightGray mb-4">
          We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Continued use of the services after changes are made constitutes acceptance of the updated policy.
        </p>

        <p className="text-lightGray mt-4">
          For any questions or concerns about this Privacy Policy, please contact us at <span className="text-accentCyan">discord.gg/zma</span>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;