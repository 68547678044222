import React from 'react';

const TermsOfService = () => {
  return (
    <div className="flex container mx-auto py-8">
      <div className="bg-darker p-6 rounded shadow-lg">
        <h1 className="text-3xl font-extrabold text-accentCyan mb-4">Terms of Service</h1>
        <p className="text-lightGray mb-4">
          Welcome to Zombie Mini Arena (ZMA)! By accessing or using our Starve.io server, Discord server, Discord bot, or Website ("services"), you agree to the following terms and conditions. If you do not agree, you must cease use of our services immediately.
        </p>

        <h2 className="text-xl font-bold text-accentCyan mb-2">1. Acceptance of Terms</h2>
        <p className="text-lightGray mb-4">
          By using our services, you confirm that you are at least 13 years old and capable of agreeing to these Terms of Service. Continued use of our services signifies your acceptance of any updates or modifications to these terms.
        </p>

        <h2 className="text-xl font-bold text-accentCyan mb-2">2. Compliance with Associated Platforms</h2>
        <p className="text-lightGray mb-4">
          By using our services, you also agree to comply with the terms and policies of any associated platforms, including but not limited to:
        </p>
        <ul className="list-disc pl-6 text-lightGray mb-4">
          <li>
            <strong>Discord:</strong> All interactions with the ZMA Discord bot or server must comply with Discord's <a href="https://discord.com/terms" className="text-accentCyan underline" target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a href="https://discord.com/guidelines" className="text-accentCyan underline" target="_blank" rel="noopener noreferrer">Community Guidelines</a>.
          </li>
          <li>
            <strong>Starve.io:</strong> Participation in ZMA-hosted Starve.io servers requires adherence to Starve.io's terms and community guidelines, which govern in-game conduct.
          </li>
        </ul>
        <p className="text-lightGray mb-4">
          Failure to comply with these external terms may result in termination of access to ZMA services without refund.
        </p>

        <h2 className="text-xl font-bold text-accentCyan mb-2">3. User Conduct</h2>
        <p className="text-lightGray mb-4">
          You agree to use the services responsibly and refrain from engaging in any activity that could harm the services, their users, or the ZMA brand. Prohibited activities include, but are not limited to:
        </p>
        <ul className="list-disc pl-6 text-lightGray mb-4">
          <li>Spamming or abusing communication features.</li>
          <li>Hacking, cheating, or exploiting bugs in the platform.</li>
          <li>Unauthorized data collection or scraping of any ZMA services.</li>
          <li>Disruptive or abusive behavior toward other users or staff.</li>
        </ul>
        <p className="text-lightGray mb-4">
          Violations will result in immediate termination of access to the services and forfeiture of any purchases or subscriptions without refund.
        </p>

        <h2 className="text-xl font-bold text-accentCyan mb-2">4. Content Ownership</h2>
        <p className="text-lightGray mb-4">
          All content, including text, images, code, and digital items offered within the services, is owned by ZMA or its respective owners. Unauthorized use, duplication, or distribution of this content is strictly prohibited. Purchases of digital items or subscriptions grant limited, non-exclusive, and revocable access to the items or features; ownership rights are not transferred.
        </p>

        <h2 className="text-xl font-bold text-accentCyan mb-2">5. Limitation of Liability</h2>
        <p className="text-lightGray mb-4">
          ZMA assumes no responsibility or liability for any loss, damage, or inconvenience resulting from your use of the services. This includes, but is not limited to:
        </p>
        <ul className="list-disc pl-6 text-lightGray mb-4">
          <li>Server downtime, resets, or updates.</li>
          <li>Loss of progress, digital items, or subscriptions.</li>
          <li>Security breaches or unauthorized access.</li>
          <li>Technical errors or software issues.</li>
        </ul>
        <p className="text-lightGray mb-4">
          All services are provided "as-is" without any guarantees, warranties, or assurances of uninterrupted operation or availability.
        </p>

        <h2 className="text-xl font-bold text-accentCyan mb-2">6. Indemnification</h2>
        <p className="text-lightGray mb-4">
          By using our services, you agree to indemnify and hold ZMA, its affiliates, and its management harmless from any claims, damages, or liabilities arising from your use of the services or breach of these Terms of Service.
        </p>

        <h2 className="text-xl font-bold text-accentCyan mb-2">7. Purchases and Monetization</h2>
        <p className="text-lightGray mb-4">
          ZMA offers premium one-time purchases and recurring subscriptions through third-party platforms such as Discord. All payments are processed by these platforms, and ZMA does not directly handle payments or store payment information. By making a purchase, you acknowledge that:
        </p>
        <ul className="list-disc pl-6 text-lightGray mb-4">
          <li>Purchases are non-refundable under any circumstances.</li>
          <li>Purchased items or subscriptions may be lost or rendered inaccessible due to server downtime, updates, resets, or other reasons.</li>
          <li>Access to purchased features or items is not guaranteed and may be revoked at the discretion of the ZMA management team without refund.</li>
        </ul>

        <h2 className="text-xl font-bold text-accentCyan mb-2">8. Revocation of Access</h2>
        <p className="text-lightGray mb-4">
          The ZMA management team reserves the right to terminate or restrict access to the services, purchased items, or subscriptions for any reason, including but not limited to rule violations, abuse of the platform, or disruptive behavior. Termination of access will result in the forfeiture of any purchased items or subscriptions, and no refunds will be issued under any circumstances.
        </p>

        <h2 className="text-xl font-bold text-accentCyan mb-2">9. Updates to Terms</h2>
        <p className="text-lightGray mb-4">
          ZMA reserves the right to update or modify these Terms of Service at any time without prior notice. Continued use of the services after changes are made constitutes acceptance of the updated terms. It is your responsibility to review these terms periodically.
        </p>

        <p className="text-lightGray mt-4">
          For questions or support, contact us via our Discord server at <span className="text-accentCyan">discord.gg/zma</span>. By using ZMA's services, you acknowledge and accept these terms in full.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;